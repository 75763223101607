<div class="component-container">
  <!-- Heading section -->
  <div class="component-heading-container">
    <div class="col-md-12" style="margin-top: 5px">
      <div class="row">
        <!-- Form Number Display -->

        <div class="col-md-2" style="display: grid">
          <select class="filter_dropdown" [(ngModel)]="selectedInput">
            <option value="formnum">Search By Form #</option>
            <option value="formname">Search By Form Name</option>
          </select>
          <h3 class="component-heading-text">
            {{ currentInternalFormNumber }}
          </h3>
        </div>

        <!-- Search Box -->
        <div class="col-md-3 fast_admin" style="padding-left: 0px">
          <!--<label class="label_heading">Search By Form Number:</label>-->

          <div
            class="selectdiv select_wrapinline"
            *ngIf="selectedInput == 'formnum'"
            style="padding-left: 3px; width: 85%"
          >
            <label>
              <ng-select
                class="custom-select"
                [items]="formsList"
                [virtualScroll]="true"
                bindLabel="InternalFormNumber"
                autofocus
                bindValue="id"
                [(ngModel)]="selectedSearchForm"
                [clearable]="true"
                (change)="onSelectSearchForm($event)"
              >
              </ng-select>
            </label>
          </div>
          <div
            class="selectdiv select_wrapinline"
            *ngIf="selectedInput == 'formname'"
            style="padding-left: 3px; width: 85%"
          >
            <label>
              <ng-select
                class="custom-select"
                [items]="formsList"
                [virtualScroll]="true"
                bindLabel="FormName"
                autofocus
                bindValue="id"
                [(ngModel)]="selectedSearchForm"
                [clearable]="true"
                (change)="onSelectSearchForm($event)"
              >
              </ng-select>
            </label>
          </div>
        </div>

        <!-- Recent Forms -->
        <div class="col-md-3 fast_admin">
          <label class="label_heading">Recent:</label>
          <div class="selectdiv select_wrapinline">
            <label>
              <ng-select
                id="activeLobList"
                class="custom-select"
                [items]="recentList"
                bindLabel=""
                bindValue=""
                [(ngModel)]="selectedRecent"
                (change)="onSelectRecentForm($event)"
                [clearable]="true"
              ></ng-select>
            </label>
          </div>
        </div>

        <!-- Tab section-->
        <div class="col-md-4">
          <!-- Tab buttons -->
          <div class="row">
            <div
              class="btn_wrapper"
              style="margin-left: auto; margin-right: 0; padding-bottom: 16px"
            >
              <button
                id="test_execution_section_btn"
                class="section_btn"
                (click)="loadAttributes()"
              >
                Attributes
              </button>
              <button
                id="test_execution_section_btn"
                class="section_btn"
                (click)="loadRules()"
              >
                Rules
              </button>
              <button
                id="test_mainenance_section_btn"
                class="section_btn"
                (click)="loadNotes()"
              >
                Notes
              </button>
              <button
                id="reporting_section_btn"
                class="section_btn"
                (click)="loadDeploymentHistory()"
              >
                History
              </button>
              <button
                id="reporting_section_btn"
                class="section_btn"
                (click)="loadRulesEngine()"
              >
                RulesEngine
              </button>
            </div>
          </div>

          <!-- Action buttons -->
          <div class="row">
            <div class="btn_wrapper" style="margin-left: auto; margin-right: 0">
              <!-- <button class="btn primary_btn btnlign" type="button" (click)="addForm()" *ngIf="activeSection === 'Attributes' && FormsWritePermission">
                New Form
              </button>
              <button class="btn primary_btn btnlign" type="button" (click)="copyForm()" *ngIf="activeSection === 'Attributes' && FormsWritePermission">
                Copy Form
              </button>

              <button class="btn primary_btn btnlign" type="button" (click)="addRuleSetup()" *ngIf="activeSection === 'Rules' && FormsWritePermission">
                New Rule Setup
              </button>

              <button class="btn primary_btn btnlign" type="button" (click)="viewLOBCodes()" *ngIf="activeSection === 'Rules' || activeSection === 'RulesEngine'">
                View LOB Info
              </button> -->
              <!-- Mat-buttons -->
              <mat-button-toggle-group class="mt-6">
                <mat-button-toggle
                  (click)="addForm()"
                  *ngIf="activeSection === 'Attributes' && FormsWritePermission"
                  >New Form</mat-button-toggle
                >
                <mat-button-toggle
                  (click)="copyForm()"
                  *ngIf="activeSection === 'Attributes' && FormsWritePermission"
                  >Copy Form</mat-button-toggle
                >
                <mat-button-toggle
                  (click)="addRuleSetup()"
                  *ngIf="activeSection === 'Rules' && FormsWritePermission"
                  >New Rule Setup</mat-button-toggle
                >
                <mat-button-toggle
                  (click)="viewLOBCodes()"
                  *ngIf="
                    activeSection === 'Rules' || activeSection === 'RulesEngine'
                  "
                  >View LOB Info</mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Heading section -->

  <app-form-page-attributes-tab
    *ngIf="activeSection === 'Attributes'"
    [currentForm]="currentForm"
    [isNewForm]="isNewForm"
    (saveForm)="onSaveForm($event)"
    (discardChanges)="onDiscardChanges()"
  ></app-form-page-attributes-tab>

  <app-form-page-rules-tab
    *ngIf="activeSection === 'Rules'"
    [currentForm]="currentForm"
    [lobList]="lobList"
    (saveForm)="onSaveForm($event)"
  ></app-form-page-rules-tab>

  <app-form-page-history-tab
    *ngIf="activeSection === 'History'"
    [currentForm]="currentForm"
    [formHistory]="formHistory"
  ></app-form-page-history-tab>

  <app-form-page-notes-tab
    *ngIf="activeSection === 'Notes'"
    [currentForm]="currentForm"
    [formHistory]="formHistory"
  ></app-form-page-notes-tab>

  <app-form-page-rules-engine
    *ngIf="activeSection === 'RulesEngine'"
    [currentForm]="currentForm"
  ></app-form-page-rules-engine>
</div>
